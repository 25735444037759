import { PLFlag, GBFlag, DEFlag } from 'mantine-flagpack';
import { pl, enGB, de } from 'date-fns/locale';

const LANGUAGES = {
	PL: {
		name: 'Polski',
		flag: PLFlag,
		dateFnsLocale: pl,
	},
	GB: {
		name: 'English',
		flag: GBFlag,
		dateFnsLocale: enGB,
	},
	DE: {
		name: 'Deutsche',
		flag: DEFlag,
		dateFnsLocale: de,
	},
} as const;

export default LANGUAGES;
